import axios from 'axios';

const request = axios.create({
  baseURL: 'https://backend-v3-api.zewst.com'
});

export const AXIOS_INSTANCE_ZEWARDS = axios.create({
  baseURL: 'https://zewards-backend-dev-549aeb29b9c6.herokuapp.com'
});

export const AXIOS_INSTANCE_ONBOARDING = axios.create({
  baseURL: 'https://revamp-api.zewst.com'
});

export const DeploymentClient = axios.create({
  baseURL: 'https://deployments.zewst.com'
});

export const AXIOS_INSTANCE_CUSTOMER_ORDERS = axios.create({
  baseURL: 'https://customer-api.zewst.com'
});

request.interceptors.request.use(async (config) => {
  const token = window.localStorage.getItem('token') || null;
  config.headers['Content-Type'] = 'application/json';
  config.headers['ngrok-skip-browser-warning'] = false;
  if (token !== null) config.headers['access-token'] = `${token}`;
  return config;
});

request.interceptors.response.use((response) => {
  return response;
});

export const client = request;
